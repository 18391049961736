import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d90577e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 info-section" }
const _hoisted_2 = { class: "col-12 col-md-7 col-lg-6" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["row", {'flex-row-reverse': _ctx.imageLocation === 'left'}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "col-12",
          ref: _ctx.uniqueRef
        }, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("h2", {
                  key: 0,
                  class: "title mb-3 mb-sm-4",
                  innerHTML: _ctx.title
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.copy)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "copy",
                  innerHTML: _ctx.copy
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ], true)
        ], 512)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["col-12 col-md-5 col-lg-6 info-section-img-container h-auto mt-4 mt-md-0", { 'd-none d-md-block': _ctx.hideImageMobile, 'ps-md-0 pe-md-5' : _ctx.imageLocation === 'left', 'pe-md-0 ps-md-5' : _ctx.imageLocation === 'right'  }])
      }, [
        (_ctx.imageSrc)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "img-fluid w-100 d-none d-md-inline",
              style: _normalizeStyle(`height: ${_ctx.imageHeight}`),
              src: `${require('@/assets/' + _ctx.imageSrc.replace(/^\//g, ''))}`,
              alt: _ctx.imageAlt
            }, null, 12, _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.imageSrc)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "img-fluid w-100 d-inline d-md-none",
              src: `${require('@/assets/' + _ctx.imageSrc.replace(/^\//g, ''))}`,
              alt: _ctx.imageAlt
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true),
        (_ctx.videoSrc)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 2,
              src: _ctx.videoSrc,
              allowfullscreen: ""
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ]))
}
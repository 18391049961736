import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutView.vue')
  },
  {
    path: '/products/:category?',
    name: 'ProductRange',
    component: () => import(/* webpackChunkName: "ProductRange" */ '../views/ProductRangeView.vue'),
    props: true
  },
  {
    path: '/products/batteries',
    redirect: '/products/accessories'
  },
  {
    path: '/product-guide',
    name: 'ProductGuide',
    component: () => import(/* webpackChunkName: "ProductGuide" */ '../views/ProductGuideView.vue'),
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUsView.vue')
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition
    // For anchors
    } else if (to.hash) {
      return { el: to.hash }
    // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {}
    } 
    // else if ((from.name === 'ProductRange') && (to.name === 'ProductRange')) {
    //   return { el: '#product-range' , top: 90 }
    // }

    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { top: 0, behavior: 'smooth' }; 
  }
})

router.beforeEach(() => {
  document.getElementById("magnetoNav")?.classList?.remove("show");
});

router.afterEach(() => {
  if(router.currentRoute.value.name && !['ContactUs', 'WarrantyRegistration'].includes(router.currentRoute.value.name as string)) {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.visibility = 'hidden';
    }
  } else {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.visibility = `visible`;
    }
  }
});

export default router

import { Vue } from "vue-class-component";
import { useMeta } from "vue-meta";

export class ScrollTop extends Vue {
  scrollTop (toScrollSelector: string = '', scrollIfAbove = true) {
    let scrollOffset = 0;
    
    if(toScrollSelector) {
      const destination = document.querySelector(toScrollSelector) as HTMLElement;
      const navBar = document.querySelector("#navbar") as HTMLElement;
      scrollOffset = destination.offsetTop - (navBar ? navBar.offsetHeight : 0);
      
      if(scrollOffset > window.scrollY && !scrollIfAbove) {
        return;
      }
    }
    

    window.scrollTo(0, scrollOffset);
    return;
  }
}

export class ThousandSeparator extends Vue {
  thousandSeparator(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export class LoadImage extends Vue {
  loadImage = (url: any) =>
    new Promise((resolve, reject) => {
      const img = new Image() as any;
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", (err: Error) => reject(err));
      img.src = url;
    });
}

export class FormatDate extends Vue {
  formatDate(date: any) {
    function appendLeadingZeroes(n: any) {
      if (n <= 9) {
        return "0" + n;
      }
      return n;
    }
    if(date) {
      if(typeof date === "string") {
        date = new Date(date);
      }
      const dateLocale = date.toLocaleDateString("en-US");
      
      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      const dateToFormat = new Date(dateLocale.replace(/-/g, "/"));
      const formattedDate = appendLeadingZeroes(dateToFormat.getDate()) + " " + months[dateToFormat.getMonth()] + " " + dateToFormat.getFullYear();
      return formattedDate;
    }
    return ''
  }
}

export class SetMetaData extends Vue {
  hasProp(obj: any = null, prop: string) {
    if (obj && prop) {
      const hasProp = Object.prototype.hasOwnProperty.call(obj, prop);

      if (hasProp) {
        return (obj[prop] !== "") ? true : false;
      }
    }
    return false;
  }

  setMetaData(meta: any, obj: any = null, definer = 'id', prefix = "") {
    //https://vue-meta.nuxtjs.org/faq/#unique-metadata
    //https://github.com/nuxt/vue-meta/blob/next/examples/vue-router/App.ts
    //https://newspatrak.com/javascript/how-to-use-vue-3-meta-with-vue-js-3/

    if (obj) {
      if (!this.hasProp(obj, definer)) {
        meta.title = prefix ? `${prefix}Magneto` : '';
        // meta.description = ''
        // meta.keywords = ''
        meta.og = {
          title: prefix ? `${prefix}Magneto` : '',
          // description: ""
        }
      } else {
        if (this.hasProp(obj, "seoTitle")) {
          meta.title = this.hasProp(obj, "seoTitle") ? (prefix + (obj?.seoTitle || ''))  : "";
          meta.og = {
            title: this.hasProp(obj, "seoTitle") ? (prefix + (obj?.seoTitle || '')) : (prefix ? `${prefix} Magneto` : '')
          }
        }

        if (this.hasProp(obj, "seoDescription")) {
          meta.description = this.hasProp(obj, "seoDescription") ? obj?.seoDescription || '' : "";
          meta.og = {
            description: this.hasProp(obj, "seoDescription") ? obj?.seoDescription || '' : ""
          };
        }

        if (this.hasProp(obj, "seoKeywords")) {
          meta.keywords = this.hasProp(obj, "seoKeywords") ? obj?.seoKeywords || '' : "";
        }
      }

      meta.og = {
        ...meta.og,
        url: `https://www.magnetopower.co.za${window.location.pathname}`,
        // type: ''
      }
    }
  }
}

export class HandleSlug extends Vue {
  joinSlug(slug: Array<string> | string): string {
    if(typeof slug == 'string') {
      return slug
    }

    if (slug.length > 1) {
      return slug.join("/");
    }
    return slug[0];
  }

  splitSlug(slug: string) {
    if(slug) {
      return slug.split("/");
    }
    return ''
  }
}

export class Capitalise extends Vue {
  capitalise(text: string, splitBy: string = ' ') {
    if (text) {
      const arr = text.split(splitBy);
      let word = [] as Array<string>;
      let final = [] as Array<string>;
      arr.forEach(item => {
        word = item.split("")
        word[0] = word[0].toUpperCase();
        final.push(word.join(""))
      })
      return final.join(" ");
    }
    return "";
  }
}

export default class Default extends Vue {
  test = "test";
}

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `Magneto`), 1)
      ]),
      "og(title)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `Magneto`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_notifications, { position: "center center" }),
    _createVNode(_component_NavBar),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Footer)
  ]))
}

import { PropType } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';

interface ImageData {
    src: string;
    srcMobile: string;
    alt: string;
    heading: string;
    // buttonText: string;
    headingClass: string;
    // buttonClass: string;
    linkTo: object | string;
    openNewPage: boolean;
}

@Options({
  props: {
    image: { type: Object as PropType<ImageData> },
    isH2: { type: Boolean, default: false},
    isH1: { type: Boolean, default: false},
    isMainBanner: { type: Boolean, default: true}
  }
})
export default class GenericBanner extends Vue {
  image!: ImageData;
  isMobile = false;

  mounted() {
    this.mediaQueryMatch();
    window.addEventListener("resize", () => this.mediaQueryMatch());
  }

  mediaQueryMatch() {
    this.isMobile = (window.matchMedia("(max-width: 575.98px)").matches) && (!this.image.srcMobile ? false : true);
  }
}


import { mixins, Options, Vue } from "vue-class-component";
import CarouselBanner from "@/components/banners/CarouselBanner.vue";
import GenericBanner from "@/components/banners/GenericBanner.vue";
import RegisterWarrantyBanner from "@/components/banners/RegisterWarrantyBanner.vue";
import InfoSection from "@/components/misc/InfoSection.vue";
import { useMeta } from "vue-meta";
import { SetMetaData } from "@/mixins/utilities";
import { HomeBannerImage } from "@/types/index"

@Options({
  components: {
    CarouselBanner,
    InfoSection,
    GenericBanner,
    RegisterWarrantyBanner,
  },
})
export default class HomeView extends mixins(SetMetaData) {
  homeBannerImages: HomeBannerImage[] = [
    {
      src: "home/desktop/HeroBanner12.jpg",
      srcMobile: "home/mobile/HeroBanner12_mobile.jpg",
      alt: "Magneto Emergency Power",
      linkTo: "https://www.tevo.co.za/all/brands/magneto",
      openNewPage: true
    },
    {
      src: "home/desktop/HeroBanner8.jpg",
      srcMobile: "home/mobile/HeroBanner8_mobile.jpg",
      alt: "Magneto Power Backup Solutions Range",
      linkTo: "/products"
    },
    {
      src: "home/desktop/HeroBanner6.jpg",
      srcMobile: "home/mobile/HeroBanner6_mobile.jpg",
      alt: "Magneto Portable Power Stations Product Range",
      linkTo: "/products/power-station"
    },
    {
      src: "home/desktop/HeroBanner10.jpg",
      srcMobile: "home/mobile/HeroBanner10_mobile.jpg",
      alt: "Magneto Rechargeable LED Bulb",
      linkTo: "/products/lighting"
    },
    {
      src: "home/desktop/HeroBanner13.jpg",
      srcMobile: "home/mobile/HeroBanner13_mobile.jpg",
      alt: "Magneto Generators",
      linkTo: "/products/generators"
    }
  ];
  introSection1 = {
    //imageSrc: "home/home-image-1v2.jpg",
    videoSrc: "https://www.youtube.com/embed/8xkhAlNGl4U?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=8xkhAlNGl4U",
    imageAlt: "Person using a Magneto Power Station to supply electricity to their home.",
    imageLocation: "right",
  };
  introSection2 = {
    //imageSrc: "home/home-image-2v2.jpg",
    videoSrc: "https://www.youtube.com/embed/Gdwj-nVNg2g?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=Gdwj-nVNg2g",
    imageAlt: "Woman using a Magneto Power Station to supply electricity to her fridge.",
    imageLocation: "left",
  };
  productBannerImages: HomeBannerImage[] = [
    {
      src: "home/desktop/ProductBanner1.jpg",
      srcMobile: "home/mobile/ProductBanner1_mobile.jpg",
      alt: "MAGNETO 150W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-150w-portable-power-station.html",
      openNewPage: true
    },
    {
      src: "home/desktop/ProductBanner2.jpg",
      srcMobile: "home/mobile/ProductBanner2_mobile.jpg",
      alt: "MAGNETO 200W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-200w-portable-power-station.html",
      openNewPage: true
    },
    { 
      // TODO Old 300W - remove when client is ready
      src: "home/desktop/ProductBanner3.jpg",
      srcMobile: "home/mobile/ProductBanner3_mobile.jpg",
      alt: "MAGNETO 300W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-300w-portable-power-station.html",
      openNewPage: true
    },
    // { 
    //   // TODO New 300W - update link when client provides info
    //   src: "home/desktop/ProductBanner6.jpg",
    //   srcMobile: "home/mobile/ProductBanner6_mobile.jpg",
    //   alt: "MAGNETO 300W PORTABLE POWER STATION",
    //   // linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-300w-portable-power-station.html",
    //   openNewPage: true
    // },
    {
      // TODO Old 600W - remove when client is ready
      src: "home/desktop/ProductBanner4.jpg",
      srcMobile: "home/mobile/ProductBanner4_mobile.jpg",
      alt: "MAGNETO 600W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-600w-portable-power-station.html",
      openNewPage: true
    },
    // {
    //   // TODO New 600W - update link when client provides info
    //   src: "home/desktop/ProductBanner7.jpg",
    //   srcMobile: "home/mobile/ProductBanner7_mobile.jpg",
    //   alt: "MAGNETO 600W PORTABLE POWER STATION",
    //   // linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-600w-portable-power-station.html",
    //   openNewPage: true
    // },
    // {
    //   // TODO New 700W - update link when client provides info
    //   src: "home/desktop/ProductBanner8.jpg",
    //   srcMobile: "home/mobile/ProductBanner8_mobile.jpg",
    //   alt: "MAGNETO 700W PORTABLE POWER STATION",
    //   // linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-700w-portable-power-station.html",
    //   openNewPage: true
    // },
    {
      // TODO Old 1200W - remove when client is ready
      src: "home/desktop/ProductBanner5.jpg",
      srcMobile: "home/mobile/ProductBanner5_mobile.jpg",
      alt: "MAGNETO 1200W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-1200w-portable-power-station.html",
      openNewPage: true
    },
    // {
    //   // TODO New 1200W - update link when client provides info
    //   src: "home/desktop/ProductBanner9.jpg",
    //   srcMobile: "home/mobile/ProductBanner9_mobile.jpg",
    //   alt: "MAGNETO 1200W PORTABLE POWER STATION",
    //   // linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-1200w-portable-power-station.html",
    //   openNewPage: true
    // },
    {
      src: "home/desktop/ProductBanner10.jpg",
      srcMobile: "home/mobile/ProductBanner10_mobile.jpg",
      alt: "MAGNETO 1500W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-1500w-portable-power-station.html",
      openNewPage: true
    },
    {
      src: "home/desktop/ProductBanner11.jpg",
      srcMobile: "home/mobile/ProductBanner11_mobile.jpg",
      alt: "MAGNETO 2200W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-2200w-portable-power-station.html",
      openNewPage: true
    },
    {
      src: "home/desktop/ProductBanner12.jpg",
      srcMobile: "home/mobile/ProductBanner12_mobile.jpg",
      alt: "MAGNETO 3600W PORTABLE POWER STATION",
      linkTo: "https://www.tevo.co.za/all/outdoor/portable-power-stations/magneto-3600w-portable-power-station.html",
      openNewPage: true
    }
  ];

  created() {
    const { meta } = useMeta({});
    this.setMetaData(meta, {}, "", "Home | ");
  }
}

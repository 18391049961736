
import { PropType } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';

interface ImageData {
    src: string;
    srcMobile: string;
    alt: string;
    // heading: string;
    // buttonText: string;
    // headingClass: string;
    // buttonClass: string;
    linkTo: string;
    openNewPage: boolean;
}

@Options({
  props: {
    images: { type: Array as PropType<Array<ImageData>> },
    uniqueId: { type: String, default: 'carouselBanner' }
  }
})
export default class CarouselBanner extends Vue {
  isMobile = false;

  mounted() {
    this.mediaQueryMatch();
    window.addEventListener("resize", () => this.mediaQueryMatch());
  }

  mediaQueryMatch() {
    this.isMobile = window.matchMedia("(max-width: 575.98px)").matches;
  }
}

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39243282"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generic-banner" }
const _hoisted_2 = { class: "generic-banner-inner" }
const _hoisted_3 = ["href", "target"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: "generic-banner-item position-relative",
        href: _ctx.image.linkTo || '',
        target: _ctx.image.openNewPage ? '_blank' : '_self',
        style: _normalizeStyle(!_ctx.image.linkTo && 'pointer-events: none;')
      }, [
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: `${require('@/assets/' + _ctx.image.srcMobile.replace(/^\//g, ''))}`,
              class: "d-block w-100",
              alt: _ctx.image.alt || ''
            }, null, 8, _hoisted_4))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: `${require('@/assets/' + _ctx.image.src.replace(/^\//g, ''))}`,
              class: "d-block w-100",
              alt: _ctx.image.alt || ''
            }, null, 8, _hoisted_5)),
        _createElementVNode("div", {
          class: _normalizeClass(`${_ctx.image.headingClass || 'top-right'} ${_ctx.isMobile ? 'generic-banner-heading-mobile px-1 text-center' : 'generic-banner-heading position-absolute'}`)
        }, [
          (_ctx.image.heading && _ctx.isH1)
            ? (_openBlock(), _createElementBlock("h1", {
                key: 0,
                class: _normalizeClass(["text-uppercase fw-normal", _ctx.isMobile ? 'py-3' : 'my-2']),
                innerHTML: _ctx.image.heading
              }, null, 10, _hoisted_6))
            : (_ctx.image.heading && _ctx.isH2)
              ? (_openBlock(), _createElementBlock("h2", {
                  key: 1,
                  class: _normalizeClass(["text-uppercase fw-normal", _ctx.isMobile ? 'py-3' : 'my-2']),
                  innerHTML: _ctx.image.heading
                }, null, 10, _hoisted_7))
              : (_ctx.image.heading)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["text-uppercase h1 fw-normal", _ctx.isMobile ? 'py-3' : 'my-2']),
                    innerHTML: _ctx.image.heading
                  }, null, 10, _hoisted_8))
                : _createCommentVNode("", true)
        ], 2)
      ], 12, _hoisted_3)
    ])
  ]))
}

import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/navigation/NavBar.vue";
import Footer from "@/components/navigation/Footer.vue";
import { useMeta } from "vue-meta";
import { IReCaptchaComposition, VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";

@Options({
  components: {
    NavBar,
    Footer,
  },
  beforeRouteUpdate(to, from, next) {
    debugger;
    this.hideRecaptcha();
  },
})
export default class App extends Vue {
  recapthcaObject: IReCaptchaComposition | undefined = undefined;

  mounted() {
    this.recapthcaObject = useReCaptcha() as IReCaptchaComposition;
    const { meta } = useMeta({
      title: "",
      // description: "",
      // keywords: "",
      // metaTags: [
      //   {
      //     property: "og:title",
      //     content: "",
      //   },
      // ],
      htmlAttrs: { lang: "en", amp: true },
    });

    this.hideRecaptcha();
  }

  async hideRecaptcha() {
    if (this.recapthcaObject) {
      // Wait until recaptcha has been loaded.
      await this.recapthcaObject.recaptchaLoaded();

      // Hide recaptcha has been loaded.
      const reCapthca = document.querySelector(".grecaptcha-badge") as any;
      if (reCapthca && !["ContactUs", "WarrantyRegistration"].includes(this.$route.name as string)) {
        reCapthca.style.visibility = `hidden`;
      } else {
        reCapthca.style.visibility = `visible`;
      }
    }
  }
}
